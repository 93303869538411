<template>
    <div class="training-card" :style="{ width: width }">
        <v-card
            :href="href !== '' ? href : '#'"
            height="60%"
            :target="externalLink ? '_blank' : '_self'"
            @click="$emit('click')"
        >
            <v-img :src="thumbnailUrl" height="100%">
                <v-icon large>play</v-icon>
            </v-img>
        </v-card>
        <!-- eslint-disable vue/no-v-html -->
        <p class="text-body-1 text-sm-body-2 mt-2" v-html="title"></p>
        <!-- eslint-enable -->
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const TrainingCardProps = Vue.extend({
    props: {
        href: {
            type: String,
            default() {
                return '';
            }
        },
        thumbnailUrl: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: false,
            default() {
                return '100%';
            }
        },
        title: {
            type: String,
            required: true
        },
        externalLink: {
            type: Boolean,
            required: false,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class TrainingCard extends TrainingCardProps {}
</script>

<style lang="scss" scoped>
.training-card::v-deep {
    opacity: 0.8;
    transition: all 0.1s ease-in-out;

    .v-image {
        background-color: black !important;
        box-shadow: none;
        cursor: pointer;
    }

    &:hover {
        .v-image {
            box-shadow: 0px 1px 2px 0px #0000001a !important;
        }
        opacity: 1;
    }

    .v-icon {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            path {
                fill: white !important;
                stroke: white !important;
            }
        }
    }
}
</style>

<template>
    <p class="text-center">
        Need additional help?
        <a href="/training" class="secondary--text font-weight-bold">
            Watch our tutorials
        </a>
    </p>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class TrainingLink extends Vue {}
</script>

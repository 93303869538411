<template>
    <v-container>
        <div class="d-none d-sm-flex flex-wrap justify-center">
            <training-card
                v-for="lesson in getTrainingLessonLinks()"
                :key="'lesson-' + lesson.id"
                :thumbnail-url="lesson.thumbnailUrl"
                :title="lesson.title"
                width="220px"
                class="mr-sm-8 mr-md-16"
                @click="showLessonModal(lesson)"
            />
        </div>

        <div class="d-sm-none">
            <h6 class="text-h6">Tutorials</h6>

            <p class="text-body-2">
                Need some help to get started? Watch our tutorials & guide
                <a href="/training" class="primary--text">here</a>
            </p>

            <carousel height="320px" :show-arrows="false" cycle>
                <v-carousel-item
                    v-for="lesson in getTrainingLessonLinks()"
                    :key="'lesson-' + lesson.id"
                >
                    <training-card
                        :thumbnail-url="lesson.thumbnailUrl"
                        :title="lesson.title"
                        @click="showLessonModal(lesson)"
                    />
                </v-carousel-item>
            </carousel>
        </div>

        <modal
            v-if="showingLesson"
            id="training-lesson-modal"
            title="Training Video"
        >
            <p class="text-subtitle-1">{{ showingLesson.title }}</p>

            <iframe
                :src="showingLesson.videoUrl"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                width="100%"
                height="420px"
            ></iframe>
        </modal>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import TrainingCard from '@/components/Training/TrainingCard.vue';
import { Modal } from '@/components/Modal';
import { Carousel } from '@/components/Carousel';

type Lesson = {
    id: number;
    title: string;
    thumbnailUrl: string;
    videoUrl: string;
};

@Component({
    components: { TrainingCard, Modal, Carousel }
})
export default class TrainingSection extends Vue {
    showingLesson: Lesson | null = null;

    customerTrainingLessonList: Array<Lesson> = [
        {
            id: 1,
            title: 'How To Properly Submit An Amp',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/821420712-52a3cab14dfeb0fe642f43a9d33e3ca75c0d7ecb321f18386cf5e220bf0ba328-d?mw=320&q=95',
            videoUrl: '//player.vimeo.com/video/365623390'
        },
        {
            id: 2,
            title: 'How To Do DIY Amps & Get Them Published Yourself!',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/917662379-6a33cdf5527ef59392cffaab1fd3d830e4241a7e8e00b84d26fcff9d4fe72c58-d?mw=320&q=95',
            videoUrl: '//player.vimeo.com/video/434510261'
        },
        {
            id: 3,
            title: 'How To Maximize Your Amps, Videos & Online Campaigns By Optimizing Your Links',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/851563109-d41cd96ff628d7f3efb342727c0820fb09e816160a5577c0524f536176cd9c32-d?mw=320&q=95',
            videoUrl: '//player.vimeo.com/video/388331480'
        }
    ];

    authorTrainingLessonList: Array<Lesson> = [
        {
            id: 1,
            title: 'ASIGO Amps, reading snapshot reports, keyword selection and more',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1123141431-51001a924e36b156cce68ac3c8368e2377707a1c3f5fe7bade707e3bf019e88d-d?mw=280&q=95',
            videoUrl: '//player.vimeo.com/video/542522680'
        },
        {
            id: 2,
            title: 'Keyword selection training, Q&A etc.',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1124888132-68c2b9b1089b10130d5ed494e503dcf5adb864b6f46584ce46b51cec549c2d42-d?mw=280&mh=130&q=95',
            videoUrl: '//player.vimeo.com/video/542521972'
        },
        {
            id: 3,
            title: 'Non-local News Headlines/Keyword selection Training',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1170888420-4f2f770ece3e7cccb38c7162fa1ce3da897524bbfefde9a9942b3fdc48bf44f1-d?mw=280&mh=130&q=95',
            videoUrl: '//player.vimeo.com/video/566304661'
        },
        {
            id: 4,
            title: "How To Reduce Your Amp Writing Time (<a href = '/files/documentation/Reduce_Amp_Writing_Time.pdf' target = 'blank'>PDF</a>)",
            thumbnailUrl:
                'https://ampifire.com/ampifire_v2/assets/img/illustrator-8.png',
            videoUrl:
                'https://ampifire.com/files/documentation/Reduce_Amp_Writing_Time.pdf'
        },
        {
            id: 5,
            title: 'How To Handle Requests With Little Or No Info',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1215989697-7dfe7f9fcd2114194b9ded4a2fbfb79445206b28a8ff7551c102a67fa8cfd6ab-d?mw=280&mh=130&q=95',
            videoUrl: '//player.vimeo.com/video/587711514'
        },
        {
            id: 6,
            title: 'AmpiFire Writing Team October 2021 Q&A',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1267784897-a3e7e31e6e4da627c23388521423ebea62485b72d06587dd1?mw=280&mh=130&q=95',
            videoUrl: '//player.vimeo.com/video/625420477?h=61ea6e5534'
        },
        {
            id: 7,
            title: 'Keyword Research for All Headlines - November 2021 Writing Team Workshop',
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1303697944-8666a2c51672515688ea9b3c209cd1f7e07c941a14429273e?mw=1700&mh=956',
            videoUrl: '//player.vimeo.com/video/647340515?h=cbc9ad7d54'
        },
        {
            id: 8,
            title: "Writing Team Workshop January 2022: Blog Intros (<a href = '/files/documentation/Blog-Intros-Workshop.pdf' target = 'blank'>PDF</a>)",
            thumbnailUrl:
                'https://i.vimeocdn.com/video/1363440332-510e8fab1d6d9910e897cab020d1727660f8699d27af0ec7cfd6258d56e18e49-d?mw=1700&mh=956',
            videoUrl: '//player.vimeo.com/video/672794404?h=ef4cb4ecb0'
        }
    ];

    showLessonModal(lesson: Lesson): void {
        this.showingLesson = lesson;

        this.$store.dispatch('modal/open', 'training-lesson-modal');
    }

    getTrainingLessonLinks(): Array<Lesson> {
        return this.$can('view', 'AuthorTrainingLessonLinks')
            ? this.authorTrainingLessonList
            : this.customerTrainingLessonList;
    }
}
</script>

<style lang="scss" scoped>
.slider::v-deep {
    .v-carousel__controls {
        .v-item-group {
            .v-carousel__controls__item {
                margin: 0 6px 0 0;

                &:before {
                    background-color: transparent !important;
                }

                .v-btn__content svg {
                    opacity: 1 !important;
                }

                .v-ripple__container {
                    visibility: hidden;
                }

                &:not(.v-item--active) {
                    .v-btn__content svg {
                        opacity: 0.2 !important;
                    }
                }
            }
        }
    }
}
</style>

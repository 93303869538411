<template>
    <v-carousel
        class="slider"
        light
        hide-delimiter-background
        delimiter-icon="square"
        :height="height"
        interval="8000"
        :cycle="cycle"
        :show-arrows="showArrows"
    >
        <template #prev="{ on, attrs }">
            <v-btn v-bind="attrs" large icon :ripple="false" v-on="on">
                <v-icon>angle-left</v-icon>
            </v-btn>
        </template>

        <template #next="{ on, attrs }">
            <v-btn v-bind="attrs" large icon :ripple="false" v-on="on">
                <v-icon>angle-right</v-icon>
            </v-btn>
        </template>

        <!-- must be v-carousel-item -->
        <slot></slot>
    </v-carousel>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const CarouselProps = Vue.extend({
    props: {
        height: {
            type: String,
            // eslint-disable-next-line quote-props
            default: () => {
                return '300px';
            }
        },
        cycle: {
            type: Boolean,
            // eslint-disable-next-line quote-props
            default: () => {
                return false;
            }
        },
        showArrows: {
            type: Boolean,
            // eslint-disable-next-line quote-props
            default: () => {
                return true;
            }
        }
    }
});

@Component
export default class Carousel extends CarouselProps {}
</script>

<style lang="scss" scoped>
.v-carousel::v-deep {
    .v-window__container {
        .v-window__prev {
            margin: 0 !important;
            background-color: unset !important;

            .v-btn:before {
                background-color: unset !important;
            }
        }

        .v-window__next {
            margin: 0 !important;
            background-color: unset !important;

            .v-btn:before {
                background-color: unset !important;
            }
        }
    }

    .v-carousel__controls {
        .v-item-group {
            .v-carousel__controls__item {
                margin: 0 6px 0 0;

                &:before {
                    background-color: transparent !important;
                }

                .v-btn__content svg {
                    opacity: 1 !important;
                }

                .v-ripple__container {
                    visibility: hidden;
                }

                &:not(.v-item--active) {
                    .v-btn__content svg {
                        opacity: 0.2 !important;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <a-banner>
        Hi {{ $store.state.user.firstname }} - Claim an Amp to work on choosing
        from the Amp requests with the highest priority level. Keep your Amp
        guide and checklist at hand - you can find them here:
        <a href="/files/documentation/Amp-Writer's-Guide.pdf" target="_blank">
            Complete AMP Writer's Guide
            <v-icon color="primary" x-small>arrow-right</v-icon>
        </a>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    }
})
export default class AmpifireBanner extends Vue {}
</script>

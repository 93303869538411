<template>
    <v-container class="ampifire-banner">
        <div
            class="ampifire-banner--content d-inline-block col-lg-10 col-md-8 col-sm-12"
        >
            <slot></slot>
        </div>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ABanner extends Vue {}
</script>

<style lang="scss" scoped>
.ampifire-banner {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 0.25em;
    background-image: url('@/assets/img/slider-background.svg');
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;

    .ampifire-banner--content {
        position: relative;
        z-index: 1;
    }
}
</style>

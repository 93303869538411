<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="d-flex justify-center py-6">
                    <v-icon left>{{ icon }}</v-icon>
                    <slot></slot>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const AMessageBarProps = Vue.extend({
    name: 'AMessageBar',
    props: {
        icon: {
            type: String,
            default() {
                return 'info-circle';
            }
        }
    }
});
@Component
export default class AMessageBar extends AMessageBarProps {}
</script>
